import React, { Component, useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import { Menu, X } from "react-feather";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import { Container } from "../../global";
import {
    Nav,
    NavItem,
    Brand,
    StyledContainer,
    NavListWrapper,
    MobileMenu,
    Mobile,
    ActionsContainer,
} from "./style";

const NAV_ITEMS = ["Features", "Docs", "Pricing", "Support"];

const NavAnchorLink = ({ item, onClick }) => (
    <AnchorLink href={`#${item.toLowerCase()}`} onClick={onClick}>
        {item}
    </AnchorLink>
);

// closeMobileMenu
const NavList = ({ mobile = false, closeMobileMenu = () => {} }) => (
    <NavListWrapper mobile={mobile}>
        <Scrollspy
            items={NAV_ITEMS.map(item => item.toLowerCase())}
            currentClassName="active"
            mobile={mobile}
            offset={-64}
        >
            {NAV_ITEMS.map(navItem => (
                <NavItem key={navItem}>
                    <NavAnchorLink item={navItem} onClick={closeMobileMenu} />
                </NavItem>
            ))}
        </Scrollspy>
    </NavListWrapper>
);
const ImageWrapper = styled.div`
    ${"" /* height: 90px; */}
    height: ${props => (props.scrolled ? `65px` : `90px`)};
    overflow: hidden;
    display: flex;
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const StyledImage = styled(Img)`
    ${"" /* width: 150px; */}
    width: 100px;
    transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Navigation = props => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            file(sourceInstanceName: { eq: "logo" }, name: { eq: "logo_2" }) {
                childImageSharp {
                    fluid(maxWidth: 150) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    useEffect(() => {
        const handleScroll = () => {
            setHasScrolled(window.pageYOffset > 64);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [setHasScrolled]);

    const toggleMobileMenu = () => setMobileMenuOpen(prevState => !prevState);
    const closeMobileMenu = () => {
        if (mobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    };

    return (
        <Nav {...props} scrolled={hasScrolled}>
            <StyledContainer>
                <Scrollspy offset={-64} item={["top"]} currentClassName="active">
                    <AnchorLink href="#top" onClick={closeMobileMenu}>
                        <StyledImage
                            scrolled={hasScrolled}
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </AnchorLink>
                </Scrollspy>
                <Mobile>
                    <button
                        onClick={toggleMobileMenu}
                        style={{ color: "black", background: "none" }}
                    >
                        {mobileMenuOpen ? (
                            <X size={24} alt="close menu" />
                        ) : (
                            <Menu size={24} alt="open menu" />
                        )}
                    </button>
                </Mobile>

                <Mobile hide>
                    <NavList mobile={false} closeMobileMenu={closeMobileMenu} />
                </Mobile>
                <ActionsContainer>
                    <Link
                        to="/app?mode=login"
                        style={{ marginRight: 20, color: "#098b8c", textDecoration: "none" }}
                    >
                        Log In
                    </Link>
                    <Link to="/app?mode=signUp">
                        <button>Sign up</button>
                    </Link>
                </ActionsContainer>
            </StyledContainer>
            <Mobile>
                {mobileMenuOpen && (
                    <MobileMenu>
                        <Container>
                            <NavList mobile closeMobileMenu={closeMobileMenu} />
                        </Container>
                    </MobileMenu>
                )}
            </Mobile>
        </Nav>
    );
};

export default Navigation;
